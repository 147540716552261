import { environment } from 'src/environments/environment';

export abstract class UserTypeConstants {
    public static readonly ADMIN = '0';
}

export abstract class CatlougeTypeConstants {
     // public static readonly catlogue = '60508372dabf5464540a2460';
     public static readonly catlogue = '633e6ff100146308e395c056';
    
}

export abstract class authKey {
    public static readonly defaultAuthKey = '60194480f127015d9011475a';
    
}


export abstract class resendOtpTimer {
    public static readonly otpTimer = 10;
    public static readonly otpTimerMessage = '';
}


export abstract class Analyticsstreaminglog {
    public static readonly logData =  {
        "device_model":"Browser",
        "timezone":"Asia/Kolkata",
        "charge_code":"WTVOD24",
        "screen":"detailPage",
        "language":"Hindi",
        "package_id":"web.digivive.whitelabled",
        "operator":"",
        "platform":"Web",
        "play_url":"",
        "type":"",
        "code":"",
        "catlogue":CatlougeTypeConstants.catlogue,
        "genre":"",
        "msisdn":"",
        "paid_status":"free",
        "record_date":"2020-09-23",
        "os_version":"10",
        "streaming_mode":"4G",
        "catloguename":"_nexgtvwhitelabeled_android",
        "start":0,
        "emailid":"",
        "channel_content":"Sushant",
        "app_name":"nexGTv Whitelabled",
        "stop":91,
        "mobile":"",
        "asset_code":"",
        "os_name":"Web",
        "category":"Bollywood Masala",
        "record_time":"02:09:07",
        "activepack":"",
        "remaining_time":"66", 
        "release_date":"01-01-2019", 
        "name":"NA", 
        "asset_cat_type":"recentlywatched"
    }
}
