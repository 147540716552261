import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-content-policies',
  templateUrl: './content-policies.component.html',
  styleUrls: ['./content-policies.component.css']
})
export class ContentPoliciesComponent implements OnInit {

  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const header = document.querySelector('header');
    this.renderer.setStyle(header, 'display', 'none');
    const footer = document.querySelector('footer');
    this.renderer.setStyle(footer, 'display', 'none');
  }

  // ngOnDestroy() {
  //   this.renderer.setStyle(document.querySelector('header'), 'display', 'block');
  //   this.renderer.setStyle(document.querySelector('footer'), 'display', 'block');
  // }

}
