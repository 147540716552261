
export const environment = {
  production: true,
  api_url: 'https://apps.digivive.com/khushbootv/',
  play_url:'https://apps.digivive.com/',
  googleTrackingCode: '',
  BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
  APP_CLOUD_NAME: 'v1603711950',
  SRC_EXT :'.webp' ,
  platformType: '1',
  api_base_url:'https://apps.digivive.com/',
};