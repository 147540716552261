<div class="maincontentarea">
    <div class="container">
        <div class="faq">
            <h1 class="text-center">FAQs</h1>
            <div class="container-txt">
                <h3>Q1) What all can I watch on Khusboo TV ?</h3>
                <p>Khusboo TV is a one-stop entertainment destination offering its users a high-quality viewing experience.</p>
            
                <h3>Q2) Is there any content on Khusboo TV which is available free and that can be watched without subscribing?</h3>
                <p>Khusboo TV may offer free or paid content and only subscribed users will be able to watch our paid content.</p>
            
                <h3>Q3) Where do I get to choose the different types of subscription packs available within the Khusboo TV application?</h3>
                <p>On clicking on any of paid content, you will be directed to a page which will display all the subscription packs. You can subscribe to any of the available packs as per your choice.</p>
            
                <h3>Q4) I am not able to stream any video, what should I do?</h3>
                <p>The problem might be because there is not enough network coverage in your region. It is suggested to set your streaming option to low quality if network signal is weak. If problem still persists, please mail us at <a href="mailto:support@khusbootv.in">support@khusbootv.in</a></p>
            
                <h3>Q5) How do I adjust the streaming quality of a video?</h3>
                <p>To adjust the streaming quality of a video on your Android mobile app, tap on the video player. You’ll see various on-screen controls. Tap on the Settings icon at the bottom of the video player. Select Video Quality. Select the desired streaming quality.</p>
                <p>To adjust streaming quality on website, click on the Settings icon at the bottom right of the video player. Select Quality. Select the desired streaming quality.</p>
                <p>To adjust streaming quality on your TV, click on the Settings icon at the bottom right of the video player. Select Quality. Select the desired streaming quality.</p>
                <p>Please note that the iOS app does not allow manual selection of quality.</p>
                
                <h3>Q6) Does Khusboo TV provide search functionality?</h3>
                <p>Yes, Khusboo TV application provides an advanced search functionality for its users to easily find content they are looking for. You need to type the search keyword in the search box available and you will be shown the relevant Movies & Shows search results.</p>
            
                <h3>Q7) Can I watch Khusboo TV on PC /Laptop as well?</h3>
                <p>Khusboo TV offers multi-screen viewing of your favourite content with the same subscription. You can now enjoy Khusboo TV service across all your personal screens - PC/Laptop/Mobile devices/Smart TV.</p>
            
                <h3>Q8) Can I watch content when I’m offline?</h3>
                <p>Yes, you can download content for offline watching on your mobile app. You’ll find the Download button on every content and can play the downloaded content from Downloads section in footer. You can choose the download quality when you tap on Download.</p>
            
                <h3>Q9) Can I resume watching content from where I left?</h3>
                <p>Yes, Khusboo TV bookmarks user’s playback position. You can resume watching content from where you left.</p>
            
                <h3>Q10) If I watch a content on one device, will my watch history be retained on another device?</h3>
                <p>User’s browsing and watch history is retained on all devices.</p>
            
                <h3>Q11) I am trying to subscribe to a pack. How long will it take to activate the subscription?</h3>
                <p>The subscription will be activated within 10 minutes once the payment is successful. Post which you can start enjoying your Khusboo TV subscription.</p>
            
                <h3>Q12) I don’t have any premium pack but when I’m trying to purchase a premium pack, I’m getting a popup that I’m already subscribed to the pack or that my device already has an active subscription.</h3>
                <p>You’re getting the popup due to one of the following reasons</p>
                <ol>
                    <li>You’re trying to purchase the pack on an iOS device which is logged in with an Apple ID already having the same Khusboo TV premium subscription. To purchase a Khusboo TV premium pack on an iOS device, make sure that the device is signed in with an Apple ID which is not subscribed to the same pack.</li>
                    <li>You’re trying to purchase the pack on an Android TV device which is logged in with a Google account already having the same Khusboo TV premium subscription. To purchase a Khusboo TV premium pack on an Android TV device, make sure that the device is signed in with a Google which is not subscribed to the same pack.</li>
                    <li>You’re trying to purchase the pack on an Android mobile app through Google Play Store which is logged in with a Google account already having the same Khusboo TV premium subscription. To purchase a Khusboo TV premium pack on an Android mobile app using Google Play Store, make sure that Google Play Store is signed in with a Google account which is not subscribed to the same pack.</li>
                </ol>
            
            
                <h3>Q13) How do I cancel a Khusboo TV subscription?</h3>
                <p>If you purchased a Khusboo TV subscription pack through wallet (Paytm, RazorPay), you can cancel the subscription using the below methods.</p>
                <p>Khusboo TV Android app</p>
                <ul>
                    <li>Open the Khusboo TV Android app</li>
                    <li>Login with your username and password</li>
                    <li>Click More</li>
                    <li>Open My Subscriptions</li>
                    <li>Click Unsubscribe</li>
                    <li>Click Yes to confirm</li>
                </ul>
                <p>Khusboo TV Website</p>
                <ul>
                    <li>Login with your Khusboo TV username and password on Khusboo TV website</li>
                    <li>Open My Accounts page</li>
                    <li>Scroll down to Plan Details</li>
                    <li>Click Unsubscribe</li>
                    <li>Click Yes to confirm</li>
                </ul>
                <p>If you purchased a Khusboo TV subscription pack through Google Play Store, you can cancel the subscription using the below steps</p>
                <ul>
                    <li>Sign in to Google Play Store using the account with which you purchased the pack</li>
                    <li>In Menu, open Payments & subscriptions</li>
                    <li>Open Subscriptions</li>
                    <li>Select Khusboo TV subscription</li>
                    <li>Click on Cancel subscription</li>
                    <li>Click Confirm</li>
                </ul>
                <p>If you purchased a Khusboo TV subscription through Apple iTunes, you can cancel the subscription using the below steps</p>
                <ul>
                    <li>Open Settings of your iOS device signed in with the same Apple ID with which you purchased the pack</li>
                    <li>Open Subscriptions</li>
                    <li>From the list of active subscriptions, open Khusboo TV subscription details</li>
                    <li>Click on Cancel</li>
                </ul>
            
                <h3>Q14) How to contact Khusboo TV in case of any support?</h3>
                <p>For any query, mail us at <a href="mailto:support@khusbootv.in">support@khusbootv.in</a></p>
            </div>
        </div>
    </div>
</div>