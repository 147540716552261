<div class="maincontentarea">
    <div class="container">
        <div class="terms">
            <h2 class="mb40">Content Policy Guidelines</h2>

            <h5>1. Sex, Obscenity, and Nudity</h5>
            <ul>
                <li>Content must not include any sexual or obscene scenes.</li>
                <li>Content should avoid depicting nudity of any person.</li>
            </ul>

            <h5>2. Violence and Crime</h5>
            <ul>
                <li>Avoid any depiction of violent, indecent, or disturbing visuals that could incite people to crime, cause disorder, or violate laws.</li>
                <li>Content must not encourage violence, criminal activities, or any unlawful behavior.</li>
            </ul>

            <h5>3. Harm and Offense</h5>
            <ul>
                <li>Content should not depict or promote harm or offense towards any living beings, including humans and animals.</li>
            </ul>

            <h5>4. Intellectual Property Infringement</h5>
            <ul>
                <li>Content must respect third-party intellectual property rights. Do not include unlicensed footage or material.</li>
                <li>Brands, logos, and trademarks should only appear with explicit permission.</li>
                <li>Avoid using famous dialogues from movies, web series, or plays without proper rights.</li>
            </ul>

            <h5>5. Smoking, Drinking, and Substance Abuse</h5>
            <ul>
                <li>Content must not promote the use of tobacco, alcohol, or narcotic substances.</li>
                <li>- If tobacco use is shown, include a 30-second anti-tobacco health spot at the beginning and midway through the content.</li>
                <li>- If alcohol or narcotics are depicted, include disclaimers like 'Drinking is injurious to health' or 'Consumption of narcotics is injurious to health.'</li>
                <li>Minors must not be shown engaging in smoking, drinking, or drug use.</li>
            </ul>

            <h5>6. Sovereignty and Security of the State</h5>
            <ul>
                <li>Content must not compromise the sovereignty, integrity, or security of India or any other state.</li>
                <li>Avoid content that could damage India's relations with foreign countries, incite violence, or disturb public order.</li>
            </ul>

            <h5>7. Fake News</h5>
            <ul>
                <li>Content must not contain impersonation or fraudulent representations.</li>
                <li>Avoid false alarms or exaggerated reports that could cause public panic.</li>
                <li>Refrain from spreading rumors or unverified information that might instill fear.</li>
            </ul>

            <h5>8. Horror and Occult</h5>
            <ul>
                <li>Avoid content that endorses or promotes occult practices, exorcism, paranormal activities, or human/animal sacrifices.</li>
                <li>Minimize prolonged depictions of horror.</li>
                <li>Do not glamorize ghosts, witches, or superstitions.</li>
                <li>Refrain from content that may instill fear or encourage blind beliefs.</li>
            </ul>

            <h5>9. Religion and Community</h5>
            <ul>
                <li>Content should not include words, signs, or representations that insult or derogate any religion, caste, or community.</li>
                <li>Avoid language or actions that might be offensive to specific communities, particularly those disadvantaged or marginalized.</li>
                <li>Do not depict actions that may damage religious places or objects held in high esteem.</li>
                <li>Avoid promoting enmity between groups based on religion, race, or language.</li>
            </ul>

            <h5>10. Usage of Animals in Content</h5>
            <ul>
                <li>If animals are featured, include a disclaimer stating, 'No animals were harmed during the making of this content.'</li>
            </ul>

            <h5>11. Gambling</h5>
            <ul>
                <li>Depictions of gambling devices or scenes are acceptable only if relevant to the plot and presented with discretion.</li>
                <li>Content must not encourage gambling or provide instructions on how to gamble.</li>
            </ul>

            <h5>12. Defamatory Statements</h5>
            <ul>
                <li>Avoid using derogatory terms or remarks against physically or mentally challenged individuals.</li>
                <li>Refrain from harsh or defamatory remarks about public figures, including celebrities, social workers, or politicians.</li>
                <li>Do not mock or insult religious or political figures.</li>
                <li>Comments about well-known personalities must be verified and appropriate.</li>
                <li>Avoid content that defames or shows contempt for the judiciary.</li>
            </ul>

            <h5>13. Use of National Flag and State Emblem</h5>
            <ul>
                <li>The national flag, emblems, and official seals of India or any state must not be used in a derogatory manner or in any way that could be interpreted as disrespectful.</li>
            </ul>

            <h5>14. Data Protection and Privacy</h5>
            <ul>
                <li>Comply with all relevant data protection laws in every jurisdiction of operation.</li>
                <li>Implement robust information security measures and promptly report any data breaches.</li>
                <li>Use personal data solely for the purposes outlined in contractual agreements.</li>
                <li>Avoid using unauthorized messaging platforms for any business-related communications, especially those involving commercial transactions.</li>
            </ul>
        </div>
    </div>
</div>