<div class="maincontentarea mt20">
    <div class="container">
       <div class="row">
           <div class="col-sm-12">               
               <div class="_about_content">
                  <h2>About us</h2>
                   <div class="_about_inner_c">
                    <p>Khusboo TV is the flagship entertainment OTT app of Khusboo Multimedia Private Limited. Khusboo Multimedia Private Limited is a Private Limited Company incorporated in 2008. It is classified as a non-govt company limited by shares and is registered at Registrar of Companies, RoC-Kolkata. Khusboo Multimedia Private Limited had pledged to fulfil India’s fancy towards art, culture, literature, and music. Now they are coming up with an exclusive OTT platform "Khusboo TV". Our expertise in delivering unique products to end consumers derives from a strong lineage technology and deep consumer insights. All product are developed keeping in mind changing trends, best in class content, ease of use, mass availability & affordability. For more details visit <a href="./">www.khusbootv.in</a>.</p>
                   </div>
               </div>
           </div>
       </div>
    </div>
</div>